import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Menu, Dropdown, Col, Avatar } from "antd";
import {
  User,
  People,
  InfoSquare,
  Calendar,
  Discount,
  Logout,
} from "react-iconly";

import avatarImg from "../../../assets/images/memoji/memoji-3.jpg";
import { useDispatch } from "react-redux";
import { LogoutSuccess } from "../../../redux/reducers/auth/authActions";

export default function HeaderUser() {
  const customise = useSelector(state => state.customise)
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(LogoutSuccess({}))
  }
  const menu = (
    <Menu theme={customise.theme == "light" ? "light" : "dark"}>
      <Menu.Item
        key={5}
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        onClick={logout}
        className="da-text-color-dark-0"
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
          <Avatar src={avatarImg} size={40} />
        </Col>
      </Dropdown>
    </Col>
  );
};
