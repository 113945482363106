import { combineReducers } from "redux";

import customiseReducer from "./reducers/customise/customiseReducer";
import authReducer from "./reducers/auth/authReducer"
import coustomerTypeReducer from "./reducers/customer-type/customerTypeReducer"
import additionalFetchReducer from "./reducers/additional-fetch/additionalFetchReducer";
import loaderReducer from "./reducers/loader/loaderReducer"
import universityReducer from "./reducers/university/universityReducer"
import serviceReducer from "./reducers/services/serviceReducer"
import documentReducer from "./reducers/documents/documentsReducer"
import locationsReducer from "./reducers/locations/locationsReducer"
import deliveryReducer from "./reducers/delivery/deliveryReducer"
import statusReducer from "./reducers/status/statusReducer"
import usersReducer from "./reducers/users/usersReducer";
import ordersReducer from "./reducers/orders/ordersReducer";
import university_serviceReducer from "./reducers/uni-services/uniServiceReducer";
import services_docsReducer from "./reducers/services-docs/serviceReducer";
import PasswordReducer from './reducers/password/passwordReducer';
import order_docsReducer from "./reducers/order-docs/orderDocsReducer";

const rootReducer = combineReducers({
  password: PasswordReducer,
  customise: customiseReducer,
  auth: authReducer,
  coustomerTypes: coustomerTypeReducer,
  apiLoader: loaderReducer,
  additionalFetch: additionalFetchReducer,
  university: universityReducer,
  services: serviceReducer,
  documents:documentReducer,
  university_services:university_serviceReducer,
  services_docs:services_docsReducer,
  order_docs:order_docsReducer,
  locations:locationsReducer,
  delivery:deliveryReducer,
  orders:ordersReducer,
  users:usersReducer,
  status:statusReducer
});

export default rootReducer;