import axios from 'axios';
import { ApiConfig } from '../../../services/api.config';

const baseURL = ApiConfig.COMMON_API;

export const SaveUniversity = (data) => {
   let url = baseURL + '/api/universities';
   return axios.post(url, data);
};

export const UpdateUniversity = (universityId, data) => {
   let url = baseURL + `/api/universities/${universityId}/update`;
   return axios.post(url, data);
};

export const DeleteUniversity = (id) => {
   let url = baseURL + '/api/universities/' + id;
   return axios.delete(url, {});
};

export const ListUniversities = (page) => {
    if(!page) return;

    const query = {
        page,
        per_page:7,
        paginate:1
    };

   let url = baseURL + `/api/universities`;
   return axios.get(url, { params: query });
};
