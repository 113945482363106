import { OrdersActionTypes } from "../reducers/orders/ordersTypes";
import { OrdersChangePageSuccess, OrdersFetchError, OrdersFetchSuccess, OrdersToggleLoader } from "../reducers/orders/ordersActions";
import { ListOrders } from "../../view/pages/orders/api.orders.service";

export const OrdersMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case OrdersActionTypes.ChangePgae: {
                dispatch(OrdersChangePageSuccess(action.payload))
                dispatch(OrdersToggleLoader(true))
                ListOrders(action.payload.page, action.payload.params).then((_response) => {
                    dispatch(OrdersToggleLoader(false))
                    dispatch(OrdersFetchSuccess(_response))
                }).catch(err => {
                    dispatch(OrdersToggleLoader(false))
                    dispatch(OrdersFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default OrdersMiddleware;