import {  DeliveryActionTypes } from "../reducers/delivery/deliveryTypes";
import { DeliveryChangePageSuccess, DeliveryFetchError, DeliveryFetchSuccess, DeliveryToggleLoader } from "../reducers/delivery/deliveryActions";
import { ListDelivery } from "../../view/pages/delivery/api.delivery.service";

export const DeliveryMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case DeliveryActionTypes.ChangePgae: {
                dispatch(DeliveryChangePageSuccess(action.payload))
                dispatch(DeliveryToggleLoader(true))
                ListDelivery(action.payload.page).then((_response) => {
                    dispatch(DeliveryToggleLoader(false))
                    dispatch(DeliveryFetchSuccess(_response))
                }).catch(err => {
                    dispatch(DeliveryToggleLoader(false))
                    dispatch(DeliveryFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default DeliveryMiddleware;