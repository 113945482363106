import { UniversityServiceActionTypes } from "./uniServicesTypes";


export const UniServiceChangePage = (value) => {
    return {
        type: UniversityServiceActionTypes.ChangePgae,
        payload: { page: value },
    };
}

export const UniServiceStoreUniversity = (value) => {
    return {
        type: UniversityServiceActionTypes.StoreUniversity,
        payload: value
    };
}

export const UniServiceChangePageSuccess = (value) => {
    return {
        type: UniversityServiceActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const UniServiceFetchSuccess = (values) => {
    return {
        type: UniversityServiceActionTypes.Set,
        payload: values,
    };
}
export const UniServiceFetchError = (values) => {
    return {
        type: UniversityServiceActionTypes.Error,
        payload: values,
    };
}
export const UniServiceUpdateSuccess = (values) => {
    return {
        type: UniversityServiceActionTypes.Update,
        payload: values,
    };
}
export const UniServiceClearValues = (value) => {
    return {
        type: UniversityServiceActionTypes.Clear,
    };
}
export const UniServiceToggleLoader = (value) => {
    return {
        type: UniversityServiceActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
