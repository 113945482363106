import { ServiceActionTypes } from "../reducers/services/servicesTypes"
import { ServiceChangePageSuccess, ServiceFetchError, ServiceFetchSuccess, ServiceToggleLoader } from "../reducers/services/serviceActions";
import { ListServices } from "../../view/pages/services/api.service.service";

export const ProductsMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case ServiceActionTypes.ChangePgae: {
                dispatch(ServiceChangePageSuccess(action.payload))
                dispatch(ServiceToggleLoader(true))
                ListServices(action.payload.page).then((_response) => {
                    dispatch(ServiceToggleLoader(false))
                    dispatch(ServiceFetchSuccess(_response))
                }).catch(err => {
                    dispatch(ServiceToggleLoader(false))     
                    dispatch(ServiceFetchError(err))
                })
                return
            }
            
            default: break;
        }
        next(action);
    };

export default ProductsMiddleware;