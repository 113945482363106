import axios from "axios";
import { ApiConfig } from "../../../services/api.config";

const baseURL = ApiConfig.COMMON_API

export const UpdatePassword = (id, data) => {
    let url = baseURL + `/api/admin/change-password/${id}`;
    return axios.post(url, data);
}

export const ListPassword = (page) => {
    if(!page) return;

    const query = {
        page,
        per_page:7
    };

    let url = baseURL + `/api/users?admins=1`;
    return axios.get(url, { params: query });
}
