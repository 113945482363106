import { ServiceActionTypes } from "./servicesTypes";


export const ServiceChangePage = (value) => {
    return {
        type: ServiceActionTypes.ChangePgae,
        payload: { page: value },
    };
}
export const ServiceChangePageSuccess = (value) => {
    return {
        type: ServiceActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const ServiceFetchSuccess = (values) => {
    return {
        type: ServiceActionTypes.Set,
        payload: values,
    };
}
export const ServiceFetchError = (values) => {
    return {
        type: ServiceActionTypes.Error,
        payload: values,
    };
}
export const ServiceUpdateSuccess = (values) => {
    return {
        type: ServiceActionTypes.Update,
        payload: values,
    };
}
export const ServiceClearValues = (value) => {
    return {
        type: ServiceActionTypes.Clear,
    };
}
export const ServiceToggleLoader = (value) => {
    return {
        type: ServiceActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
