import { ServicesDocsActionTypes } from "../reducers/services-docs/servicesTypes"
import { ServiceChangePageSuccess, ServiceFetchError, ServiceFetchSuccess, ServiceToggleLoader } from "../reducers/services-docs/serviceActions";
import { ListServiceDocuments  } from "../../view/pages/services-docs/api.services-docs.service";

export const ServiceDocsMiddleWare = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case ServicesDocsActionTypes.ChangePgae: {
                dispatch(ServiceChangePageSuccess(action.payload))
                dispatch(ServiceToggleLoader(true))
                ListServiceDocuments(action.payload.page).then((response) => {
                    const _response = response.data;
                    dispatch(ServiceToggleLoader(false))
                    dispatch(ServiceFetchSuccess(_response))
                }).catch(err => {
                    dispatch(ServiceToggleLoader(false))     
                    dispatch(ServiceFetchError(err))
                })
                return
            }
            
            default: break;
        }
        next(action);
    };

export default ServiceDocsMiddleWare;