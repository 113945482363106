import { DocumentActionTypes } from "./documentTypes";


export const DocumentChangePage = (value) => {
    return {
        type: DocumentActionTypes.ChangePgae,
        payload: { page: value },
    };
}
export const DocumentChangePageSuccess = (value) => {
    return {
        type: DocumentActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const DocumentFetchSuccess = (values) => {
    return {
        type: DocumentActionTypes.Set,
        payload: values,
    };
}
export const DocumentFetchError = (values) => {
    return {
        type: DocumentActionTypes.Error,
        payload: values,
    };
}
export const DocumentUpdateSuccess = (values) => {
    return {
        type: DocumentActionTypes.Update,
        payload: values,
    };
}
export const DocumentClearValues = (value) => {
    return {
        type: DocumentActionTypes.Clear,
    };
}
export const DocumentToggleLoader = (value) => {
    return {
        type: DocumentActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
