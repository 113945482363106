import { LocationsActionTypes } from "./locationsTypes";


export const LocationChangePage = (value) => {
    return {
        type: LocationsActionTypes.ChangePgae,
        payload: { page: value },
    };
}
export const LocationChangePageSuccess = (value) => {
    return {
        type: LocationsActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const LocationFetchSuccess = (values) => {
    return {
        type: LocationsActionTypes.Set,
        payload: values,
    };
}
export const LocationFetchError = (values) => {
    return {
        type: LocationsActionTypes.Error,
        payload: values,
    };
}
export const LocationUpdateSuccess = (values) => {
    return {
        type: LocationsActionTypes.Update,
        payload: values,
    };
}
export const LocationClearValues = (value) => {
    return {
        type: LocationsActionTypes.Clear,
    };
}
export const LocationToggleLoader = (value) => {
    return {
        type: LocationsActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
