import axios from "axios";
import { ApiConfig } from "../../../services/api.config";

const baseURL = ApiConfig.COMMON_API

export const SaveLocation = (data) => {
    let url = baseURL + "/api/locations";
    return axios.post(url, data);
}

export const UpdateLocation = (data) => {
    let url = baseURL + `/api/locations/${data.id}`;
    return axios.put(url, data);
}

export const DeleteLocation = (id) => {
    let url = baseURL + "/api/locations/" + id;
    return axios.delete(url, {});
}

export const ListLocations = (page) => {
    if(!page) return;

    const query = {
        page,
        per_page:7,
        paginate:1
    };

    let url = baseURL + `/api/locations`;
    return axios.get(url, { params: query });
}
