import { UniversityActionTypes } from "../reducers/university/universityTypes";
import { UniversityChangePageSuccess, UniversityFetchError, UniversityFetchSuccess, UniversityToggleLoader } from "../reducers/university/universityActions"
import { ListUniversities } from "../../view/pages/university/api.university.service";

export const UniversitysMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case UniversityActionTypes.ChangePgae: {
                dispatch(UniversityChangePageSuccess(action.payload))
                dispatch(UniversityToggleLoader(true))
                ListUniversities(action.payload.page).then((_response) => {
                    dispatch(UniversityToggleLoader(false))
                    dispatch(UniversityFetchSuccess(_response))
                }).catch(err => {
                    dispatch(UniversityToggleLoader(false))
                    dispatch(UniversityFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default UniversitysMiddleware;