import { useEffect, useState } from 'react';
import { Category, Filter } from 'react-iconly';

import {
   RiBlazeFill,
   RiGlobeLine,
   RiLayoutTop2Line,
   RiTruckLine,
   RiUserLine,
   RiLoaderLine,
   RiRotateLockLine
} from 'react-icons/ri';
import {
   isAuthorized,
   accessToOnlySuperAdmin,
   accessToSalesAdminAlso,
   accessToEveryone,
} from '../../authorisation';

const useAuthroizedPages = () => {
   const [authPages, setAuthPages] = useState([]);
   const [pages, setPages] = useState([]);
   const currentAdminType = localStorage.getItem('admin_type');

   useEffect(() => {
      setPages([
         {
            header: 'PAGES',
         },
         {
            id: 'Orders',
            title: 'Orders',
            icon: <RiBlazeFill set='curved' className='remix-icon' />,
            navLink: '/orders',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToEveryone, currentAdminType),
         },
         {
            id: 'Users',
            title: 'Users',
            icon: <RiUserLine set='curved' className='remix-icon' />,
            navLink: '/users',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToSalesAdminAlso, currentAdminType),
         },
         {
            id: 'University',
            title: 'University',
            icon: <Category set='curved' className='remix-icon' />,
            navLink: '/university',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            id: 'Services',
            title: 'Services',
            icon: <Filter set='curved' className='remix-icon' />,
            navLink: '/services',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            id: 'Documents',
            title: 'Documents',
            icon: <RiLayoutTop2Line set='curved' className='remix-icon' />,
            navLink: '/documents',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            id: 'Locations',
            title: 'Locations',
            icon: <RiGlobeLine set='curved' className='remix-icon' />,
            navLink: '/locations',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            id: 'Delivery',
            title: 'Delivery',
            icon: <RiTruckLine set='curved' className='remix-icon' />,
            navLink: '/delivery',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            id: 'Status',
            title: 'Status',
            icon: <RiLoaderLine set='curved' className='remix-icon' />,
            navLink: '/status',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            id: 'Password',
            title: 'Password',
            icon: <RiRotateLockLine set='curved' className='remix-icon' />,
            navLink: '/password',
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
      ]);

      return () => {
         setPages([]);
      };
   }, [currentAdminType]);

   useEffect(() => {
      const authorizedPages = pages.filter((page) => page.isAuthorizedForCurrentAdminType === true);
      setAuthPages(authorizedPages);

      return () => {
         setAuthPages([]);
      };
   }, [pages]);

   return authPages;
};


export default useAuthroizedPages;
