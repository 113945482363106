import { ServicesDocsActionTypes } from "./servicesTypes";


export const ServiceChangePage = (value) => {
    return {
        type: ServicesDocsActionTypes.ChangePgae,
        payload: { page: value },
    };
}

export const ServiceStoreSelectedService = (value) => {
    return {
        type: ServicesDocsActionTypes.StoreSelectedService,
        payload: value
    };
}

export const ServiceChangePageSuccess = (value) => {
    return {
        type: ServicesDocsActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const ServiceFetchSuccess = (values) => {
    return {
        type: ServicesDocsActionTypes.Set,
        payload: values,
    };
}
export const ServiceFetchError = (values) => {
    return {
        type: ServicesDocsActionTypes.Error,
        payload: values,
    };
}
export const ServiceUpdateSuccess = (values) => {
    return {
        type: ServicesDocsActionTypes.Update,
        payload: values,
    };
}
export const ServiceClearValues = (value) => {
    return {
        type: ServicesDocsActionTypes.Clear,
    };
}
export const ServiceToggleLoader = (value) => {
    return {
        type: ServicesDocsActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
