import {UsersActionTypes } from "../reducers/users/usersTypes";
import { UsersChangePageSuccess, UsersFetchError, UsersFetchSuccess, UsersToggleLoader } from "../reducers/users/usersActions";
import { ListUsers } from "../../view/pages/users/api.users.service";

export const UsersMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case UsersActionTypes.ChangePgae: {
                dispatch(UsersChangePageSuccess(action.payload))
                dispatch(UsersToggleLoader(true))
                ListUsers(action.payload.page, action.payload.params).then((_response) => {
                    dispatch(UsersToggleLoader(false))
                    dispatch(UsersFetchSuccess(_response))
                }).catch(err => {
                    dispatch(UsersToggleLoader(false))
                    dispatch(UsersFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default UsersMiddleware;