import axios from 'axios';
import { ApiConfig } from '../../../services/api.config';

const baseURL = ApiConfig.COMMON_API;

export const UpdateAccountStatus = (data, id) => {
   let url = baseURL + `/api/users/${id}/change-status`;
   return axios.post(url, data);
};

export const DeleteUser = (id) => {
   let url = baseURL + '/api/users/' + id;
   return axios.delete(url, {});
};

export const ListUsers = (page = 1, paramsData = {}) => {
   const params = {
      ...paramsData,
      page,
      per_page: 7,
   };

   let url = baseURL + `/api/users`;
   return axios.get(url, { params });
};

export const ListUsersTypes = () => {
   let url = baseURL + `/api/usertypes`;
   return axios.get(url);
};
