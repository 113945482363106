import { OrdersActionTypes } from "./ordersTypes";


export const OrdersChangePage = ( page, params) => {
    return {
        type: OrdersActionTypes.ChangePgae,
        payload: { page, params },
    };
}
export const OrdersChangePageSuccess = (value) => {
    return {
        type: OrdersActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const OrdersFetchSuccess = (values) => {
    return {
        type: OrdersActionTypes.Set,
        payload: values,
    };
}
export const OrdersFetchError = (values) => {
    return {
        type: OrdersActionTypes.Error,
        payload: values,
    };
}
export const OrdersUpdateSuccess = (values) => {
    return {
        type: OrdersActionTypes.Update,
        payload: values,
    };
}
export const OrdersClearValues = (value) => {
    return {
        type: OrdersActionTypes.Clear,
    };
}
export const OrdersToggleLoader = (value) => {
    return {
        type: OrdersActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
