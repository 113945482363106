import axios from "axios";
import { ApiConfig } from "../../../services/api.config";

const baseURL = ApiConfig.COMMON_API

export const SaveService = (data) => {
    let url = baseURL + "/api/services";
    return axios.post(url, data);
}

export const UpdateService = (data) => {
    let url = baseURL + `/api/services/${data.id}`;
    return axios.put(url, data);
}

export const DeleteService = (id) => {
    let url = baseURL + "/api/services/" + id;
    return axios.delete(url);
}

export const ListServices = (page) => {
    if(!page) return;

    const query = {
        page,
        per_page:7
    };

    let url = baseURL + `/api/services`;
    return axios.get(url, { params: query });
}

export const ListAllServices = () => {
    let url = baseURL + `/api/services`;
    return axios.get(url);
}