import axios from "axios";
import { ApiConfig } from "../../../services/api.config";

const baseURL = ApiConfig.COMMON_API

export const SaveDelivery = (data) => {
    let url = baseURL + "/api/delivery-price";
    return axios.post(url, data);
}

export const UpdateDelivery = (id, data) => {
    let url = baseURL + `/api/delivery-price/${id}`;
    return axios.post(url, data);
}

export const DeleteDelivery = (id) => {
    let url = baseURL + "/api/Deliverys/" + id;
    return axios.delete(url, {});
}

export const ListDelivery = (page) => {
    if(!page) return;

    const query = {
        page,
        per_page:7
    };

    let url = baseURL + `/api/delivery-price`;
    return axios.get(url, { params: query });
}
