import { PasswordActionTypes } from "./passwordTypes";


export const PasswordChangePage = (value) => {
    return {
        type: PasswordActionTypes.ChangePgae,
        payload: { page: value },
    };
}
export const PasswordChangePageSuccess = (value) => {
    return {
        type: PasswordActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const PasswordFetchSuccess = (values) => {
    return {
        type: PasswordActionTypes.Set,
        payload: values,
    };
}
export const PasswordFetchError = (values) => {
    return {
        type: PasswordActionTypes.Error,
        payload: values,
    };
}
export const PasswordUpdateSuccess = (values) => {
    return {
        type: PasswordActionTypes.Update,
        payload: values,
    };
}
export const PasswordClearValues = (value) => {
    return {
        type: PasswordActionTypes.Clear,
    };
}
export const PasswordToggleLoader = (value) => {
    return {
        type: PasswordActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
