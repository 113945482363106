import axios from 'axios';
import { ApiConfig } from '../../../services/api.config';
import Utlis from '../../../services/utlis';

const baseURL = ApiConfig.COMMON_API;

export const SaveDocuments = (data) => {
   let url = baseURL + '/api/documents';
   return axios.post(url, data);
};

export const UpdateDocuments = (data) => {
   let url = baseURL + `/api/documents/${data.id}`;
   return axios.put(url, data);
};

export const SaveProductImage = (id, data) => {
   let url = baseURL + '/product/' + id + '/image';
   let form = new FormData();
   form.append('image', data);
   return axios.post(url, form);
};

export const DeleteDocuments = (id) => {
   let url = baseURL + '/api/documents/' + id;
   return axios.delete(url, {});
};

export const ListDocuments = (page) => {
   if (!page) return;

   const query = {
      page,
      per_page: 7,
   };

   let url = baseURL + `/api/documents`;
   return axios.get(url, { params: query });
};
