import { StatusActionTypes } from "./statusTypes";


export const StatusChangePage = (value) => {
    return {
        type: StatusActionTypes.ChangePgae,
        payload: { page: value },
    };
}
export const StatusChangePageSuccess = (value) => {
    return {
        type: StatusActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const StatusFetchSuccess = (values) => {
    return {
        type: StatusActionTypes.Set,
        payload: values,
    };
}
export const StatusFetchError = (values) => {
    return {
        type: StatusActionTypes.Error,
        payload: values,
    };
}
export const StatusUpdateSuccess = (values) => {
    return {
        type: StatusActionTypes.Update,
        payload: values,
    };
}
export const StatusClearValues = (value) => {
    return {
        type: StatusActionTypes.Clear,
    };
}
export const StatusToggleLoader = (value) => {
    return {
        type: StatusActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
