import axios from "axios";
import { ApiConfig } from "../../../services/api.config";

const baseURL = ApiConfig.COMMON_API

export const SaveServicesDocuments = (selectedServiceDetails , data) => {
    const serviceId = selectedServiceDetails.id;

    let url = baseURL + `/api/services/${serviceId}/add-documents`;
    return axios.post(url, data);
}

export const UpdateUniversityService = (data , university_id) => {
    let url = baseURL + `/api/universities/${university_id}/change-price`;
    return axios.post(url, data);
}

export const SaveServiceImage = (id, data) => {
    let url = baseURL + "/service/" + id + "/image";
    let form = new FormData()
    form.append("image", data)
    return axios.post(url, form);
}

export const DeleteServicesDocuments = (item) => {
    const document_id = item.pivot.document_id;
    const service_id = item.pivot.service_id;

    const data = {
       document_id:[document_id] 
    }

    let url = baseURL + `/api/services/${service_id}/remove-documents`;
    return axios.post(url, data );
}

export const  ListServiceDocuments = (id, page = 1) => {
    const query = {
        paginate: 1,
        page,
        per_page: 7,
    };

    let url = baseURL + `/api/services/${id}`;
    return axios.get(url, { params: query });
}