export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const authToken = localStorage.getItem('access_token');
      if (authToken) {
        config.headers.Authorization = `bearer ${authToken}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(res => {
    let response = res.data
    return response;
  }, (err) => {
    if (err.response) {
      if (401 === err.response.status && err.response.data.status_code === 'token_expired') {
         const retryOriginalRequest = new Promise((resolve, reject) => {
              const  originalRequest  = err.config;
               const newAuthToken  = err.response.data.token;
               localStorage.setItem('access_token', newAuthToken);
               originalRequest.headers['Authorization'] = `bearer ${newAuthToken}`;;
               resolve(axios(originalRequest));
            });

            return retryOriginalRequest;
      }
    }

    let errResponse = JSON.stringify(err.response)
    let error = new Error(errResponse)
    throw error
  });
}
