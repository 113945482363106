export const   OrderDocsActionTypes = {
    ChangePgae: "[order-docs] change api pagination",
    ChangePgaeSuccess: "[order-docs] change api pagination success",
    SelectedOrder : "[orders] select order",
    Set: "[order-docs] set values",
    Update: "[order-docs] update values",
    Clear: "[order-docs] clear values",
    Error: "[order-docs] fetch error",
    ToggleLoader: "[order-docs] toggle loader",
    StoreSelectedService: "[order-docs] store selected service ",
};