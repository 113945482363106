import { UniversityActionTypes } from "./universityTypes";


export const UniversityChangePage = (value) => {
    return {
        type: UniversityActionTypes.ChangePgae,
        payload: { page: value },
    };
}
export const UniversityChangePageSuccess = (value) => {
    return {
        type: UniversityActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const UniversityFetchSuccess = (values) => {
    return {
        type: UniversityActionTypes.Set,
        payload: values,
    };
}
export const UniversityFetchError = (values) => {
    return {
        type: UniversityActionTypes.Error,
        payload: values,
    };
}
export const UniversityUpdateSuccess = (values) => {
    return {
        type: UniversityActionTypes.Update,
        payload: values,
    };
}
export const UniversityClearValues = (value) => {
    return {
        type: UniversityActionTypes.Clear,
    };
}
export const UniversityToggleLoader = (value) => {
    return {
        type: UniversityActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
