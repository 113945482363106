import { DocumentActionTypes } from "../reducers/documents/documentTypes";
import { DocumentChangePageSuccess, DocumentFetchError, DocumentFetchSuccess, DocumentToggleLoader } from "../reducers/documents/documentsActions";
import { ListDocuments } from "../../view/pages/documents/api.documents.service";

export const DocumentMiddleWare = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case DocumentActionTypes.ChangePgae: {
                dispatch(DocumentChangePageSuccess(action.payload))
                dispatch(DocumentToggleLoader(true))
                ListDocuments(action.payload.page).then((_response) => {
                    dispatch(DocumentToggleLoader(false))
                    dispatch(DocumentFetchSuccess(_response))
                }).catch(err => {
                    dispatch(DocumentToggleLoader(false))
                    dispatch(DocumentFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default DocumentMiddleWare;