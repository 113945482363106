import {  UniversityServiceActionTypes } from "../reducers/uni-services/uniServicesTypes";
import { UniServiceChangePageSuccess, UniServiceFetchError, UniServiceFetchSuccess, UniServiceToggleLoader } from "../reducers/uni-services/uniServiceActions";
import { ListUniversityServices  } from "../../view/pages/uni-services/api.uni-service.service";

export const UniversityUniServiceMiddleWare = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case  UniversityServiceActionTypes.ChangePgae: {
                dispatch(UniServiceChangePageSuccess(action.payload))
                dispatch(UniServiceToggleLoader(true))
                ListUniversityServices(action.payload.page).then((response) => {
                    const _response = response.data;
                    const updatedResponse = _response.map((resp) => {
                        const pivot = resp.pivot;
                        const newPrice = parseInt(pivot.price); 
                        const newPivot = {...pivot , price:newPrice }
                        return { ...resp , pivot:newPivot  };
                    }) 

                    dispatch(UniServiceToggleLoader(false))
                    dispatch(UniServiceFetchSuccess(updatedResponse))
                }).catch(err => {
                    dispatch(UniServiceToggleLoader(false))     
                    dispatch(UniServiceFetchError(err))
                })
                return
            }
            
            default: break;
        }
        next(action);
    };

export default UniversityUniServiceMiddleWare;