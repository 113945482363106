import { DeliveryActionTypes } from "./deliveryTypes";


export const DeliveryChangePage = (value) => {
    return {
        type: DeliveryActionTypes.ChangePgae,
        payload: { page: value },
    };
}
export const DeliveryChangePageSuccess = (value) => {
    return {
        type: DeliveryActionTypes.ChangePgaeSuccess,
        payload: { page: value },
    };
}
export const DeliveryFetchSuccess = (values) => {
    return {
        type: DeliveryActionTypes.Set,
        payload: values,
    };
}
export const DeliveryFetchError = (values) => {
    return {
        type: DeliveryActionTypes.Error,
        payload: values,
    };
}
export const DeliveryUpdateSuccess = (values) => {
    return {
        type: DeliveryActionTypes.Update,
        payload: values,
    };
}
export const DeliveryClearValues = (value) => {
    return {
        type: DeliveryActionTypes.Clear,
    };
}
export const DeliveryToggleLoader = (value) => {
    return {
        type: DeliveryActionTypes.ToggleLoader,
        payload: { loading : value}
    };
}
