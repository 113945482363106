// Routes Imports
import useAuthorizedRoutes from './Pages';

// Merge Routes
const useRoutes = () => {
   const Pages = useAuthorizedRoutes();
   const Routes = [...Pages];
   return Routes;
};

export default useRoutes;
