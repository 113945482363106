export const authorisationLevel = {
   super_admin: 'super_admin',
   verification_admin: 'verification_admin',
   sales_admin: 'sales_admin',
};

// Access Levels
export const accessToEveryone = [
   authorisationLevel.sales_admin,
   authorisationLevel.super_admin,
   authorisationLevel.verification_admin,
];

export const accessToSalesAdminAlso = [
   authorisationLevel.sales_admin,
   authorisationLevel.super_admin,
];

export const accessToOnlySuperAdmin = [authorisationLevel.super_admin];

// Access Checker
export const isAuthorized = (accessTo, currentAdminType) => {
   if (accessTo.includes(currentAdminType)) {
      return true;
   } else {
      return false;
   }
};