import axios from "axios";
import { ApiConfig } from "../../../services/api.config";

const baseURL = ApiConfig.COMMON_API

export const SaveStatus = (data) => {
    let url = baseURL + "/api/order-statuses";
    return axios.post(url, data);
}

export const UpdateStatus = (id, data) => {
    let url = baseURL + `/api/order-statuses/${id}`;
    return axios.post(url, data);
}

export const DeleteStatus = (id) => {
    let url = baseURL + `/api/order-statuses/${id}`;
    return axios.delete(url, {});
}

export const ListStatus = (page) => {
    if(!page) return;

    const query = {
        page,
        per_page:7,
        paginate:1
    };

    let url = baseURL + `/api/order-statuses`;
    return axios.get(url, { params: query });
}
