import {  StatusActionTypes } from "../reducers/status/statusTypes";
import { StatusChangePageSuccess, StatusFetchError, StatusFetchSuccess, StatusToggleLoader } from "../reducers/status/statusActions";
import { ListStatus } from "../../view/pages/status/api.status.service";

export const StatusMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case StatusActionTypes.ChangePgae: {
                dispatch(StatusChangePageSuccess(action.payload))
                dispatch(StatusToggleLoader(true))
                ListStatus(action.payload.page).then((_response) => {
                    dispatch(StatusToggleLoader(false))
                    dispatch(StatusFetchSuccess(_response))
                }).catch(err => {
                    dispatch(StatusToggleLoader(false))
                    dispatch(StatusFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default StatusMiddleware;