import { LocationsActionTypes } from "../reducers/locations/locationsTypes";
import { LocationChangePageSuccess, LocationFetchError, LocationFetchSuccess, LocationToggleLoader } from "../reducers/locations/locationsActions";
import { ListLocations } from "../../view/pages/locations/api.locations.service";

export const LocationsMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case LocationsActionTypes.ChangePgae: {
                dispatch(LocationChangePageSuccess(action.payload))
                dispatch(LocationToggleLoader(true))
                ListLocations(action.payload.page).then((_response) => {
                    dispatch(LocationToggleLoader(false))
                    dispatch(LocationFetchSuccess(_response))
                }).catch(err => {
                    dispatch(LocationToggleLoader(false))
                    dispatch(LocationFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default LocationsMiddleware;