import axios from 'axios';
import { ApiConfig } from '../../../services/api.config';

const baseURL = ApiConfig.COMMON_API;

export const ChangeOrderStatus = (data, orderId) => {
   let url = baseURL + `/api/service-orders/${orderId}/change-order-status`;
   return axios.post(url, data);
};

export const ViewAdditionalAmount = (orderId) => {
   let url = baseURL + `/api/service-orders/${orderId}/additional-amounts`;
   return axios.get(url);
};

export const AddAdditionalAmount = (data, orderId) => {
   let url = baseURL + `/api/service-orders/${orderId}/add-additional-amount`;
   return axios.post(url, data);
};

export const UpdateAditionalAmount = (data, orderId, id) => {
   let url = baseURL + `/api/service-orders/${orderId}/update-additional-amount/${id}`;
   return axios.post(url, data);
};

export const DeleteAditionalAmount = (item) => {
   const id = item.id;
   const orderId = item.service_order_id;

   let url = baseURL + `/api/service-orders/${orderId}/remove-additional-amount/${id}`;
   return axios.post(url, {});
};

export const DeleteLocation = (id) => {
   let url = baseURL + '/api/locations/' + id;
   return axios.delete(url, {});
};

export const ListOrders = (page, paramsData) => {
   if (!page) return;
   
   const params = {
      ...paramsData,
      page,
      per_page: 7,
   };

   let url = baseURL + `/api/service-orders`;
   return axios.get(url, { params });
};
