import useAuthroizedPages from './pages';

function UseNavigation() {
   const pages = useAuthroizedPages();
   const navigation = [...pages];

   return navigation;
}

export default UseNavigation;
