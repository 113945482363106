export const AdditionalFetchActionTypes = {
    SetUniversityLanguages : "[additional-fetch] set university languages values",
    SetUniversityServices : "[additional-fetch] set university services values",
    SetServicesDocuments : "[additional-fetch] set services documents values",
    SetOrderStatus : "[additional-fetch] set order status values",
    SetAllServices : "[additional-fetch] set all services values",
    SetUserTypes : "[additional-fetch] set user type values",
    Update: "[additional-fetch] update values",
    Clear: "[additional-fetch] clear values",
    Set: "[additional-fetch] set some values"
};