import { Engagespot } from '@engagespot/react-component';
import React from 'react';

function NotificationEngageSpot() {
   const ENGAGESPOT_API_KEY = `${process.env.REACT_APP_ENGAGESPOT_API_KEY}`;
   const userEmail = localStorage.getItem('userEmail');

   console.log(ENGAGESPOT_API_KEY);
   console.log(userEmail);

   return (
      <>
         {userEmail && (
            <Engagespot
               panelOpenByDefault={false}
               apiKey={ENGAGESPOT_API_KEY}
               userId={userEmail}
               theme={{
                  notificationButton: {
                     iconFill: '#0C7434',
                  },
                  colors: {
                     brandingPrimary: '#0C7434',
                     colorSecondary: '#F89428',
                  },
                  feedItem: {
                    //  hoverBackground: '#f8f9fc',
                     hoverBackground: '#ebfafa',
                  },
                  dropdown: {
                     hoverBackground: '#ecebfa',
                     menuItemHoverBackground: '#ecebfa',
                  },
               }}
            ></Engagespot>
         )}
      </>
   );
}

export default NotificationEngageSpot;