import React, { useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Layout, Button, Row, Col, Tag } from 'antd';
import { RiCloseLine, RiMenuFill } from 'react-icons/ri';
import { Search } from 'react-iconly';

import HeaderSearch from './HeaderSearch';
import HeaderUser from './HeaderUser';
import HeaderNotifications from './HeaderNotifications';
import HeaderText from './HeaderText';
import NotificationEngageSpot from './../../../EngageSpot/index';

const { Header } = Layout;

export default function MenuHeader(props) {
   const { setVisible } = props;

   const [searchHeader, setSearchHeader] = useState(false);
   const [searchActive, setSearchActive] = useState(false);
   const admin_type = localStorage.getItem('admin_type');
   const userEmail = localStorage.getItem('userEmail');

   // Redux
   const customise = useSelector((state) => state.customise);

   // Focus
   const inputFocusRef = useRef(null);
   const inputFocusProp = {
      ref: inputFocusRef,
   };

   // Search Active
   setTimeout(() => setSearchActive(searchHeader), 100);

   const searchClick = () => {
      setSearchHeader(true);

      setTimeout(() => {
         inputFocusRef.current.focus({
            cursor: 'start',
         });
      }, 200);
   };

   // Mobile Sidebar
   const showDrawer = () => {
      setVisible(true);
      setSearchHeader(false);
   };

   // Children
   const headerChildren = () => {
      return (
         <Row className='da-w-100 da-position-relative' align='middle' justify='space-between'>
            <Col className='da-mobile-sidebar-button da-mr-24'>
               <Button
                  className='da-mobile-sidebar-button'
                  type='text'
                  onClick={showDrawer}
                  icon={
                     <RiMenuFill
                        size={24}
                        className='remix-icon da-text-color-black-80 da-text-color-dark-30'
                     />
                  }
               />
            </Col>

            <Col
               flex='1'
               style={{ display: !searchHeader ? 'none' : 'block' }}
               className={`da-pr-md-0 da-pr-16 da-header-search ${
                  searchActive && 'da-header-search-active'
               }`}
            >
               <HeaderSearch inputFocusProp={inputFocusProp} setSearchHeader={setSearchHeader} />
            </Col>

            {!searchHeader && <HeaderText />}

            <div className='csm-headuser-container'>
               {admin_type && (
                  <Col>
                     <Row align='middle'>
                        <Col className='da-d-flex-center da-mr-4 csm-uppercase csm-font-bold csm-color-primary'>
                           {admin_type.replace('_', ' ')}
                        </Col>
                     </Row>
                  </Col>
               )}

               <Col>
                  <Row align='middle'>
                     <Col className='da-d-flex-center da-mr-4'></Col>
                        <NotificationEngageSpot />
                  </Row>
               </Col>

               <Col>
                  <Row align='middle'>
                     <Col className='da-d-flex-center da-mr-4'></Col>
                     <HeaderUser />
                  </Row>
               </Col>
            </div>
         </Row>
      );
   };

   return (
      <Header>
         <Row justify='center' className='da-w-100'>
            {customise.contentWidth == 'full' && <Col span={24}>{headerChildren()}</Col>}

            {customise.contentWidth == 'boxed' && (
               <Col xxl={20} xl={22} span={24}>
                  {headerChildren()}
               </Col>
            )}
         </Row>
      </Header>
   );
}
