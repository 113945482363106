import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from './rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore , persistReducer } from "redux-persist";
import UniversityMiddleWare from './middlewares/university-middleware';
import ServiceMiddleware from './middlewares/service-middleware';
import DocumentsMiddleware from './middlewares/documents-middleware';
import UniversityServiceMiddleware from './middlewares/uni-service-middleware';
import ServiceDocsMiddleware from './middlewares/services-docs-middleware';
import LocationsMiddleware from './middlewares/locations-middleware';
import DeliveryMiddleware from './middlewares/delivery-middleware';
import StatusMiddleware from './middlewares/status-middleware';
import UsersMiddleware from './middlewares/users-middleware';
import OrdersMiddleware from './middlewares/orders-middleware';
import PasswordMiddleWare from './middlewares/password-middleware';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const middleware = [thunk, createDebounce(),
    PasswordMiddleWare,
    UniversityMiddleWare,
    ServiceMiddleware,
    DocumentsMiddleware,
    UniversityServiceMiddleware,
    ServiceDocsMiddleware,
    LocationsMiddleware,
    OrdersMiddleware,
    UsersMiddleware,
    DeliveryMiddleware,
    StatusMiddleware
  ]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistConfig = {
  key: 'root',
  storage
}

 const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer , {}, composeEnhancers(applyMiddleware(...middleware)))
export const persistor = persistStore(store);

export default store