import axios from 'axios';
import { ApiConfig } from '../../../services/api.config';

const baseURL = ApiConfig.COMMON_API;

export const SaveUniversityServices = (universityDetails, data) => {
   const university_id = universityDetails.id;

   let url = baseURL + `/api/universities/${university_id}/add-service`;
   return axios.post(url, data);
};

export const UpdateUniversityService = (data, university_id) => {
   let url = baseURL + `/api/universities/${university_id}/change-price`;
   return axios.post(url, data);
};

export const SaveServiceImage = (id, data) => {
   let url = baseURL + '/service/' + id + '/image';
   let form = new FormData();
   form.append('image', data);
   return axios.post(url, form);
};

export const DeleteUniversityServices = (item) => {
   const university_id = item.pivot.university_id;
   const service_id = item.pivot.service_id;

   const data = {
      service_id,
   };

   let url = baseURL + `/api/universities/${university_id}/remove-service`;
   return axios.post(url, data);
};

export const ListUniversityServices = (id, page = 1) => {
   const query = {
      paginate: 1,
      page,
      per_page: 8,
   };

   let url = baseURL + `/api/universities/${id}`;
   return axios.get(url, { params: query });
};
