import {  PasswordActionTypes } from "../reducers/password/passwordTypes";
import { PasswordChangePageSuccess, PasswordFetchError, PasswordFetchSuccess, PasswordToggleLoader } from "../reducers/password/passwordActions";
import { ListPassword } from "../../view/pages/password/api.password.service";

export const PasswordMiddleware = ({ dispatch }) => (next) =>
    async (action) => {
        switch (action.type) {
            case PasswordActionTypes.ChangePgae: {
                dispatch(PasswordChangePageSuccess(action.payload))
                dispatch(PasswordToggleLoader(true))
                ListPassword(action.payload.page).then((_response) => {
                    dispatch(PasswordToggleLoader(false))
                    dispatch(PasswordFetchSuccess(_response))
                }).catch(err => {
                    dispatch(PasswordToggleLoader(false))
                    dispatch(PasswordFetchError(err))
                })
                return
            }

            default: break;
            
         }

        next(action);
    };

export default PasswordMiddleware;