import { lazy, useEffect, useState } from 'react';
import {
   isAuthorized,
   accessToOnlySuperAdmin,
   accessToSalesAdminAlso,
   accessToEveryone,
} from '../../authorisation';

const useAuthorizedRoutes = () => {
   const [pages, setPages] = useState([]);
   const currentAdminType = localStorage.getItem('admin_type');

   useEffect(() => {
      setPages([
         {
            path: '/university',
            component: lazy(() => import('../../view/pages/university')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/locations',
            component: lazy(() => import('../../view/pages/locations')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/delivery',
            component: lazy(() => import('../../view/pages/delivery')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/status',
            component: lazy(() => import('../../view/pages/status')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/orders',
            component: lazy(() => import('../../view/pages/orders')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToEveryone, currentAdminType),
         },
         {
            path: '/users',
            component: lazy(() => import('../../view/pages/users')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToSalesAdminAlso, currentAdminType),
         },
         {
            path: '/university-services',
            component: lazy(() => import('../../view/pages/uni-services')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/services-documents',
            component: lazy(() => import('../../view/pages/services-docs')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/order-documents',
            component: lazy(() => import('../../view/pages/order-docs')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToEveryone, currentAdminType),
         },
         {
            path: '/documents',
            component: lazy(() => import('../../view/pages/documents')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/password',
            component: lazy(() => import('../../view/pages/password')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/services',
            component: lazy(() => import('../../view/pages/services')),
            layout: 'VerticalLayout',
            protected: true,
            isAuthorizedForCurrentAdminType: isAuthorized(accessToOnlySuperAdmin, currentAdminType),
         },
         {
            path: '/pages/error-page',
            component: lazy(() => import('../../view/pages/error')),
            layout: 'FullLayout',
         },
         {
            path: '/auth/login',
            component: lazy(() => import('../../view/pages/auth/login')),
            layout: 'FullLayout',
         },
      ]);

      return () => {
         setPages([]);
      };
   }, [currentAdminType]);

   return pages;
};

export default useAuthorizedRoutes;
